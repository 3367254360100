import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { getExchanges } from "../../api/get-forex-data";

const columns: GridColDef[] = [
  { field: "sellAmount", headerName: "Sold Amount", width: 100 },
  { field: "sellCurrency", headerName: "Currency", width: 100 },
  { field: "buyAmount", headerName: "Bought Amount", width: 150 },
  { field: "buyCurrency", headerName: "Currency", width: 100 },
  { field: "midMarketRate", headerName: "Market Rate", width: 100 },
  { field: "providerRate", headerName: "Provider Rate", width: 150 },
  { field: "profit", headerName: "Providers Profit", width: 150 },
  { field: "fxRequirement", headerName: "Annual FX Flow", width: 150 },
  { field: "fxSaving", headerName: "Annual Cost Saving", width: 150 },
];

const Exchanges = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["exchanges"],
    queryFn: getExchanges,
  });

  if (isLoading)
    return (
      <Stack
        sx={{ width: "100%", height: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={45} />
      </Stack>
    );
  if (error) return <Typography>Error: {error.message}</Typography>;

  return (
    <Stack
      sx={{ width: "100%", height: "100%" }}
      alignItems="center"
      justifyContent="center"
    >
      <Box sx={{ width: "60%" }}>
        <DataGrid
          rows={data || []}
          columns={columns}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          // checkboxSelection
          // disableSelectionOnClick
        />
      </Box>
    </Stack>
  );
};

export default Exchanges;
