import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { useState } from "react";

import { useParams } from "react-router-dom";
import { calculateProfit } from "../../../api/get-forex-data";
import { currencies } from "../../../constants/currencies";
import { decryptString, encryptString } from "../../../utils/crypto";

import { ProfitResponse } from "../../../types/forex-data";
import ErrorCard from "./error-card";
import TradeCard from "./trade-card";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const CalculatorForm = () => {
  const { encryptedData } = useParams();

  // const encryptedString = encryptString("calvin@ebury.com");
  // console.log(encryptedString);

  let decryptedString = "testUserWithoutEmail@gmail.com";
  if (encryptedData) {
    decryptedString = decryptString(decodeURIComponent(encryptedData));
  }

  const [dateValue, setDateValue] = useState<Dayjs | null>(null);
  const [timeValue, setTimeValue] = useState<Dayjs | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [viewForm, setViewForm] = useState(true);
  const [viewResult, setViewResult] = useState(false);
  const [viewError, setViewError] = useState(false);
  const [profitResponse, setProfitResponse] = useState<ProfitResponse | null>(
    null
  );

  const formik = useFormik({
    initialValues: {
      sellAmount: "",
      sellCurrency: "",
      buyAmount: "",
      buyCurrency: "",
      fxRequirement: "",
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      // const date = dateValue?.format("YYYY-MM-DD");
      // const time = timeValue?.format("HH:mm");
      // const date_time = date + "-" + time;

      // Combine the date and time into one string and create a Dayjs object
      const localDateTime = dayjs(
        `${dateValue?.format("YYYY-MM-DD")}T${timeValue?.format("HH:mm")}`
      );
      // Convert to UTC (GMT)
      const dateTimeInGMT = localDateTime.utc();
      // Format as needed (e.g., "YYYY-MM-DD-HH:mm")
      const date_time = dateTimeInGMT.format("YYYY-MM-DD-HH:mm");

      try {
        const profit = await calculateProfit(
          values.sellCurrency,
          values.buyCurrency,
          values.sellAmount,
          values.buyAmount,
          date_time,
          decryptedString,
          values.fxRequirement
        );
        if (profit !== null) {
          setViewForm(false);
          setProfitResponse(profit);
          setViewResult(true);
        }
        if (profit === null) {
          setViewForm(false);
          setViewError(true);
        }
      } catch (error) {
        console.error("Error fetching Forex rates:");
      }
    },
  });

  return (
    <>
      {viewForm && (
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    renderInput={(params: any) => (
                      <TextField id="date" name="date" {...params} />
                    )}
                    value={dateValue}
                    onChange={(newValue) => setDateValue(newValue)}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Time"
                    renderInput={(params) => (
                      <TextField id="time" name="time" {...params} />
                    )}
                    value={timeValue}
                    onChange={(newValue) => setTimeValue(newValue)}
                  />
                </LocalizationProvider>
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                label="Sold Amount"
                variant="outlined"
                id="sellAmount"
                name="sellAmount"
                value={formik.values.sellAmount}
                onChange={formik.handleChange}
              />

              <FormControl fullWidth>
                <InputLabel id="sold-currency">Sold Currency</InputLabel>
                <Select
                  labelId="sold-currency"
                  id="sellCurrency"
                  name="sellCurrency"
                  value={formik.values.sellCurrency}
                  onChange={formik.handleChange}
                >
                  {currencies.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                label="Bought Amount"
                variant="outlined"
                id="bought-amount"
                name="buyAmount"
                value={formik.values.buyAmount}
                onChange={formik.handleChange}
              />
              <FormControl fullWidth>
                <InputLabel id="bought-currency-currency">
                  Bought Currency
                </InputLabel>
                <Select
                  labelId="bought-currency"
                  id="buyCurrency"
                  name="buyCurrency"
                  value={formik.values.buyCurrency}
                  onChange={formik.handleChange}
                >
                  {currencies.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack>
              <InputLabel id="bought-currency-currency">
                What is your annual FX requirement {"(ZAR)"}?
              </InputLabel>
              <TextField
                fullWidth
                label="Annual FX requirement"
                variant="outlined"
                id="fx-requirement"
                name="fxRequirement"
                value={formik.values.fxRequirement}
                onChange={formik.handleChange}
              />
            </Stack>
            <Button
              variant="contained"
              type="submit"
              disabled={
                isLoading ||
                !formik.values.sellCurrency ||
                !formik.values.buyCurrency ||
                !formik.values.sellAmount ||
                !formik.values.buyAmount ||
                !formik.values.fxRequirement
              }
            >
              {isLoading ? "Loading..." : "Calculate"}
            </Button>
          </Stack>
        </form>
      )}

      {viewResult && <TradeCard profitResponse={profitResponse} />}
      {viewError && <ErrorCard />}
    </>
  );
};

export default CalculatorForm;
