import {
  browserSessionPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../../firebase";

import { useMutation } from "@tanstack/react-query";

export const useSignInWithEmailAndPassword = (options = {}) => {
  const { mutate: handleSignInWithEmailAndPassword, ...rest } = useMutation({
    mutationFn: async (data: { email: string; password: string }) => {
      const { email, password } = data;
      await setPersistence(auth, browserSessionPersistence);
      return signInWithEmailAndPassword(auth, email, password);
    },
  });
  return { handleSignInWithEmailAndPassword, ...rest };
};

export const runSignOut = async (): Promise<void> => {
  return auth.signOut();
};

export const useSignOut = (options = {}) => {
  const { mutate: handleSignOut, ...rest } = useMutation({
    mutationFn: () => {
      return runSignOut();
    },
  });
  return { handleSignOut, ...rest };
};
