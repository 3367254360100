import { Button, Stack, Typography } from "@mui/material";

const ErrorCard = () => {
  return (
    <Stack sx={{ width: 620 }} justifyContent="center" alignItems="center">
      <Typography fontWeight="bold" color="#5AC3D7" variant="h5">
        Error
      </Typography>
      <Typography color="#5AC3D7" variant="body1">
        Something wrong happened. Please check your trade details and try again.
      </Typography>
      <Typography color="#5AC3D7" variant="body1">
        Please check your trade details and try again.
      </Typography>
      <Button
        onClick={() => {
          window.location.reload();
        }}
        variant="contained"
      >
        {" "}
        Retry calculation
      </Button>
    </Stack>
  );
};

export default ErrorCard;
