import Box from "@mui/material/Box";

import { StyledFooter, Text } from "./styles";

const Footer = () => {
  return (
    <StyledFooter>
      {/* <BrandMark /> */}
      <Box mb={1} />
      <Text>
        {"Copyright © "}
        FXSolutionsSA {new Date().getFullYear()}
        {"."}
      </Text>
    </StyledFooter>
  );
};

export default Footer;
