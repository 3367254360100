import { Navigate, Outlet } from "react-router-dom";
import { urls } from "../../constants/urls";
import { useAuth } from "../../context/auth-context";
import { AppMain } from "./styles";

const DataLayout = () => {
  const { userLoggedIn } = useAuth();
  return (
    <>
      {!userLoggedIn && <Navigate to={`${urls.signInUrl}`} replace={true} />}
      <AppMain>
        <Outlet />
      </AppMain>
    </>
  );
};

export default DataLayout;
