// @mui
import { Box, Button, Stack, TextField } from "@mui/material";
// @ld
import { useFormik } from "formik";
import { useState } from "react";
import { Heading3 } from "../../styles/typography";
import { Content } from "./styles";

import { useSignInWithEmailAndPassword } from "../../api/auth";

const SignIn = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { handleSignInWithEmailAndPassword } = useSignInWithEmailAndPassword({
    onSuccess: () => {
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      handleSignInWithEmailAndPassword(values);
    },
  });
  return (
    <Content>
      <Box pt={5} pb={3}>
        <Heading3 align="center">Sign in</Heading3>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2} sx={{ pt: 5 }}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            type="password"
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Signing in..." : "Sign in"}
          </Button>
        </Stack>
      </form>
    </Content>
  );
};

export default SignIn;
