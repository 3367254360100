import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ThemeProvider from "./context/ThemeProvider";
import GlobalStyles from "./styles/global";

import { SpeedInsights } from "@vercel/speed-insights/react";

import { urls } from "./constants/urls";

import Data from "./pages/data";
import Home from "./pages/home";

import AuthLayout from "./components/lay-out/auth";
import PageLoader from "./components/pageloader";
import SignIn from "./pages/auth/sign-in";

import DataLayout from "./components/lay-out/data";
import { AuthProvider } from "./context/auth-context";

const App = () => {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <AuthProvider>
        <SpeedInsights />
        <Router>
          <React.Suspense fallback={<PageLoader />}>
            <Routes>
              <Route path={`${urls.authUrl}`} element={<AuthLayout />}>
                <Route path={`${urls.signInUrl}`} element={<SignIn />} />
              </Route>
              <Route path={`${urls.root}`} element={<Home />} />
              <Route path={`${urls.root}/:encryptedData`} element={<Home />} />
              <Route path={`${urls.authUrl}`} element={<DataLayout />}>
                <Route path={`${urls.dataUrl}`} element={<Data />} />
              </Route>
            </Routes>
          </React.Suspense>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
