import { Content } from "./styles";

import CalculatorForm from "./form";

const Widget = () => {
  return (
    <>
      <Content>
        <CalculatorForm />
      </Content>
    </>
  );
};

export default Widget;
