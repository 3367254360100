import axios from "axios";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { traderMadeKey } from "../../config";
import { urls } from "../../constants/urls";
import { firestore } from "../../firebase";
import { ForexRateResponse, ProfitResponse } from "../../types/forex-data";

export async function getForexRate(
  date_time: string,
  sellCurrency: string,
  buyCurrency: string
): Promise<ForexRateResponse> {
  const currency = `${sellCurrency}${buyCurrency}`;
  const api_key = `${traderMadeKey}`;
  const response = await axios.get<ForexRateResponse>(urls.apiurl, {
    params: {
      currency: currency,
      date_time: date_time,
      api_key: api_key,
    },
  });
  return response.data;
}

const postExchange = async (values: object) => {
  await addDoc(collection(firestore, "exchanges"), {
    ...values,
    created: Date.now(),
  });
  return;
};

export const getExchanges = async () => {
  const exchangeList: any[] = [];
  const querySnapshot = await getDocs(collection(firestore, "exchanges"));
  querySnapshot.forEach((doc) => {
    const documentData = doc.data();
    documentData.id = doc.id;
    exchangeList.push(documentData);
  });

  return exchangeList;
};

export async function calculateProfit(
  sellCurrency: string,
  buyCurrency: string,
  sellAmount: string,
  buyAmount: string,
  date_time: string,
  email: string,
  fxRequirement?: string
): Promise<ProfitResponse | null> {
  try {
    const response = await getForexRate(date_time, sellCurrency, buyCurrency);

    // Calculate mid-market rate
    const avgHighLow = (response.high + response.low) / 2;
    const avgOpenClose = (response.open + response.close) / 2;
    const midMarketRate = (avgHighLow + avgOpenClose) / 2;

    // Calculate the provider's effective rate
    const providerRate = Number(buyAmount) / Number(sellAmount);

    // Calculate profit:
    // Fair amount should be sellAmount * midMarketRate,
    // so profit is the difference between fair value and what was actually received.
    const fairAmount = Number(sellAmount) * midMarketRate;
    const profit = fairAmount - Number(buyAmount);
    // Alternatively, profit can be calculated as:
    // (midMarketRate - providerRate) * Number(sellAmount)

    // Calculate profit percentage relative to the fair value
    const profitPercentage = (profit / fairAmount) * 100;

    // Assuming no markup, the FX saving equals the profit.
    const fxSaving = profit;

    // Adjust annualSaving based on fxRequirement as a percentage factor, if provided.
    const annualSaving = (profit * Number(fxRequirement || 0)) / 100;

    await postExchange({
      sellCurrency,
      buyCurrency,
      sellAmount,
      buyAmount,
      midMarketRate,
      providerRate,
      profit,
      fxRequirement,
      annualSaving,
      email,
    });

    return {
      buyCurrency,
      sellCurrency,
      providerRate,
      profitPercentage,
      profit,
      midMarketRate,
      fxSaving,
      annualSaving,
    };
  } catch (error) {
    console.error("Error fetching Forex rates:", error);
    return null;
  }
}
