import { Box, Button, Stack } from "@mui/material";
import HelpModal from "../../components/help";
import Hero from "../../components/hero";
import Widget from "../../components/widget";

import { useState } from "react";
import { IoHelpCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { colors } from "../../constants/colors";
import { urls } from "../../constants/urls";
import { Heading4 } from "../../styles/typography";

const Home = () => {
  const navigate = useNavigate();

  const [viewHelp, setViewHelp] = useState(false);
  return (
    <Stack direction="row" sx={{ py: 20, height: "100%" }}>
      <Stack
        sx={{ width: "50%", height: "100%" }}
        justifyContent="center"
        alignItems="center"
      >
        <Hero />
      </Stack>
      <Stack
        sx={{ width: "50%", height: "100%" }}
        justifyContent="center"
        alignItems="center"
      >
        {/* <Stack
          sx={{ width: "100%" }}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button
            variant="outlined"
            onClick={() => navigate(urls.signInUrl, { replace: true })}
          >
            <Heading4 color={colors.white}>Sign In</Heading4>
          </Button>
        </Stack> */}
        <Widget />
        <Box sx={{ height: "16px" }} />
        <Button
          color="secondary"
          endIcon={<IoHelpCircleSharp size={35} color={colors.blue1} />}
          onClick={() => {
            setViewHelp(true);
          }}
        >
          <Heading4 color={colors.blue1}>Help</Heading4>
        </Button>
        <HelpModal
          open={viewHelp}
          onClose={() => {
            setViewHelp(false);
          }}
        />
      </Stack>
    </Stack>
  );
};

export default Home;
