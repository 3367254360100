import { Button, Stack, Typography } from "@mui/material";
import { ProfitResponse } from "../../../types/forex-data";

const TradeCard = ({
  profitResponse,
}: {
  profitResponse: ProfitResponse | null;
}) => {
  return (
    <Stack sx={{ width: 620 }} justifyContent="center" alignItems="center">
      <Stack
        spacing={1}
        sx={{ width: "100%", height: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography fontWeight="bold" color="#5AC3D7" variant="h5">
          Your Provider
        </Typography>
        <Stack direction="row" spacing={1}>
          <Typography color="#5AC3D7" variant="body1">
            Your exchange rate was{":"}
          </Typography>
          <Typography fontWeight="bold" color="#5AC3D7" variant="body1">
            {profitResponse?.providerRate
              ? `${(1 / Number(profitResponse.providerRate)).toFixed(2)}`
              : "N/A"}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography color="#5AC3D7" variant="body1">
            The real market rate{" "}
            {`(${profitResponse?.sellCurrency}/${profitResponse?.buyCurrency})`}{" "}
            was
            {":"}
          </Typography>
          <Typography fontWeight="bold" color="#5AC3D7" variant="body1">
            {profitResponse?.midMarketRate
              ? `${(1 / profitResponse.midMarketRate).toFixed(2)}`
              : "N/A"}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography color="#5AC3D7" variant="body1">
            Your broker's markup was{":"}
          </Typography>
          <Typography fontWeight="bold" color="#5AC3D7" variant="body1">
            {profitResponse?.profitPercentage
              ? `${profitResponse?.profitPercentage.toFixed(2)}%`
              : "N/A"}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography color="#5AC3D7" variant="body1">
            Your broker made a profit of{":"}
          </Typography>
          <Typography fontWeight="bold" color="#5AC3D7" variant="body1">
            {profitResponse?.profit
              ? `${profitResponse?.profit}${profitResponse?.sellCurrency}`
              : "N/A"}
          </Typography>
        </Stack>

        {/* <Typography fontWeight="bold" color="#5AC3D7" variant="h5">
          With Us
        </Typography> */}

        <Button
          onClick={() => {
            window.location.reload();
          }}
          variant="contained"
        >
          {" "}
          Calculate again
        </Button>
      </Stack>
    </Stack>
  );
};

export default TradeCard;
