import { Navigate, Outlet } from "react-router-dom";
import { urls } from "../../constants/urls";
import { useAuth } from "../../context/auth-context";
import Footer from "../footer";
import { AuthContainer, Card, Main } from "./styles";

const AuthLayout = () => {
  const { userLoggedIn } = useAuth();
  return (
    <>
      {userLoggedIn && <Navigate to={`${urls.dataUrl}`} replace={true} />}
      <AuthContainer>
        <Main $isCentered>
          <Card>
            <Outlet />
          </Card>
        </Main>
        <Footer />
      </AuthContainer>
    </>
  );
};

export default AuthLayout;
