export const currencies = [
  "GBP",
  "USD",
  "EUR",
  "ZAR",
  "JPY",
  "CHF",
  "CNY",
  "NZD",
  "SGD",
  "INR",
  "AUD",
  "CAD",
  "HKD",
  "MYR",
  "NOK",
  "RUB",
  "SEK",
];
