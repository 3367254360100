import CryptoJS from "crypto-js";

const secretKey = "your-secret-key";

export const encryptString = (plainText: string) => {
  const encrypted = CryptoJS.AES.encrypt(plainText, secretKey).toString();
  return base64UrlEncode(encrypted);
};

export const decryptString = (cipherText: string) => {
  const decrypted = base64UrlDecode(cipherText);
  const bytes = CryptoJS.AES.decrypt(decrypted, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const base64UrlEncode = (str: string) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(str))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
};

const base64UrlDecode = (str: string) => {
  str = str.replace(/-/g, "+").replace(/_/g, "/");
  const pad = str.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error(
        "InvalidLengthError: Input base64url string is the wrong length to be decoded"
      );
    }
    str += new Array(5 - pad).join("=");
  }
  return CryptoJS.enc.Base64.parse(str).toString(CryptoJS.enc.Utf8);
};
